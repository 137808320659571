<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          dense
          label="Full Name"
          v-model="userInfo.fullName"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-row align-center justify-center"
      >
        <vue-country-code
          class="mr-2 mt-n7"
          @onSelect="onSelect"
          :enabledCountryCode="true"
          :defaultCountry="'sn'"
          :preferredCountries="['sn', 'fr', 'ma']"
        >
        </vue-country-code>
        <v-text-field
          outlined
          dense
          v-mask="['#########']"
          label="Phone Number"
          v-model="userInfo.phoneNumber"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          dense
          label="Email"
          v-model="userInfo.email"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          outlined
          dense
          :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]"
          label="Passengers"
          v-model="userInfo.passengers"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          outlined
          dense
          :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]"
          label="Luggages"
          v-model="userInfo.luggages"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          outlined
          dense
          :items="[0, 1, 2]"
          label="Baby Seats"
          v-model="userInfo.babySeats"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-btn @click="validateForm">Validate</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {

  directives: { mask },

  data() {
    return {
      countryCode: "",
      userInfo: {
        fullName: "",
        phoneNumber: "",
        email: "",
        passengers: 1,
        luggages: 0,
        babySeats: 0,
      },
      emailPattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },

  methods: {
    validateForm() {
      if(!this.userInfo.fullName || !this.userInfo.phoneNumber || !this.userInfo.email || !this.emailPattern.test(this.userInfo.email)) return false
      return console.log('Okay')
    },

    onSelect({ name, iso2, dialCode }) {
      this.countryCode = `+${dialCode}`;
      console.log(name, iso2, dialCode);
    },
  },
};
</script>

<style>
</style>