export const getDistanceAndTime = (source, dest) => {
  console.log(source, dest)
  const request = {
    origins: [new google.maps.LatLng(`${source.lat}`, `${source.lng}`)],
    destinations: [new google.maps.LatLng(`${dest.lat}`, `${dest.lng}`)],
    travelMode: google.maps.TravelMode.DRIVING,
    avoidHighways: false,
    avoidTolls: false,
  }

  return new Promise((resolve, reject) => {
    new google.maps.DistanceMatrixService().getDistanceMatrix(request).then((response) => {
      resolve({ distance: response.rows[0].elements[0].distance.text, time: response.rows[0].elements[0].duration.text })
    }).catch(err => {
      reject(err)
    })
  })
}