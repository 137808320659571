<template>
  <v-sheet class="pa-5" elevation="1">
    <h2>Ajouter Order</h2>

    <v-divider class="mt-3"></v-divider>
    <!-- <Sandbox /> -->
    <v-row class="mt-5">
      <v-col cols="12" md="6"
        ><v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              dense
              append-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              @blur="dateTmp = parseDate(date)"
              v-on="on"
              label="Date"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            v-model="dateTmp"
            @input="dateMenu = false"
            locale="fr"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          dense
          outlined
          v-mask="['##:##']"
          label="Time"
          placeholder="12:00"
          v-model="time"
        ></v-text-field>
      </v-col>
      <v-col cols="12" v-if="!gotVan">
        <v-btn color="success" @click="checkVans">Find Vans</v-btn>
      </v-col>

      <v-col cols="12" v-if="showVan && !gotVan">
        <select-van :fetchVan="fetchVan" :date="dateTime" />
      </v-col>

      <div v-if="gotVan">
        <v-subheader>Van: {{ selectedVan.customId }}</v-subheader>
        <v-subheader>Driver: {{ selectedDriver.customId }}</v-subheader>
      </div>
    </v-row>
    <v-row v-if="gotVan">
      <v-col cols="12" sm="6" v-for="item in formText" :key="item.id">
        <v-text-field
          outlined
          dense
          v-if="item.id == 'customerEmail'"
          :rules="[rules.required, rules.email]"
          :label="item.title"
          v-model="item.value"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          v-else-if="item.id == 'customerName'"
          :rules="[rules.required]"
          :label="item.title"
          v-model="item.value"
        ></v-text-field>
        <div v-else-if="item.id == 'phoneNumber'" class="d-flex flex-row align-center justify-center">
          <vue-country-code
            class="mr-2"
            @onSelect="onSelect"
            :enabledCountryCode="true"
            :defaultCountry="'sn'"
            :preferredCountries="['sn', 'fr', 'ma']"
          >
          </vue-country-code>
          <v-text-field
            outlined
            dense
            hide-details
            v-mask="['#########']"
            :label="item.title"
            v-model="item.value"
          ></v-text-field>
        </div>
        <!-- <v-text-field
          outlined
          dense
          v-else-if="item.id == 'phoneNumber'"
          v-mask="['+221 ### #### ###']"
          :rules="[rules.required]"
          placeholder="+221 ### #### ###"
          :label="item.title"
          v-model="item.value"
        ></v-text-field> -->
        <div v-else-if="item.id == 'passengers'">
          <v-select
            outlined
            dense
            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]"
            :label="item.title"
            v-model="item.value"
          ></v-select>
        </div>
        <div v-else-if="item.id == 'luggages'">
          <v-select
            outlined
            dense
            :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]"
            :label="item.title"
            v-model="item.value"
          ></v-select>
        </div>
        <!-- <v-text-field
          outlined
          dense
          v-else-if="item.id == 'passengers'"
          v-mask="['##']"
          :rules="[rules.required]"
          :label="item.title"
          v-model="item.value"
        ></v-text-field> -->
        <!-- <v-text-field
          outlined
          dense
          v-else-if="item.id == 'luggages'"
          v-mask="['##']"
          :rules="[rules.required]"
          :label="item.title"
          v-model="item.value"
        ></v-text-field> -->
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          outlined
          dense
          :items="[0, 1, 2]"
          :label="'Baby Seats'"
          v-model="babySeats"
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="gotVan">
      <order-map :getMapData="getMapData" :key="shouldReRender" />
    </div>
    <v-subheader class="red--text" v-if="errorMsg">{{ errorMsg }}</v-subheader>
    <div v-if="showConfirmTrip" class="d-flex justify-space-between mt-10">
      <v-btn color="primary" @click="testRules()">Confirm Trip</v-btn>

      <v-btn color="error" @click="changeAddress">Change Address</v-btn>
    </div>
  </v-sheet>
</template>

<script>
import { mask } from "vue-the-mask";
import OrderMap from "../../components/order/OrderMap.vue";
import SelectVan from "../../components/order/SelectVan.vue";
import { getDistanceAndTime } from "../../utils/mapsUtils";
import driverRest from "../../utils/driverRestTime";
import dayjs from "dayjs";
import Sandbox from '../../components/Sandbox.vue';
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default {
  components: { OrderMap, SelectVan, Sandbox },
  directives: { mask },

  data() {
    return {
      showVan: false,
      shouldReRender: false,
      dateMenu: false,
      dateTmp: "",
      date: "19-05-2022",
      time: "10:00",
      dateTime: "",
      selectedVan: null,
      selectedDriver: null,
      tripTime: null,
      tripDistance: null,
      pickUp: {},
      dropOff: {},
      showConfirmTrip: false,
      gotVan: false,
      errorMsg: "",
      emailPattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail."
        },
      },
      formText: [
        {
          id: "customerName",
          title: "Customer Name",
          value: "Temp Name",
          validation: false,
        },
        {
          id: "phoneNumber",
          title: "Customer Phone Number",
          value: "1233333",
          validation: false,
        },
        {
          id: "customerEmail",
          title: "Customer Email",
          value: "temp@gmail.com",
          validation: false,
        },
        {
          id: "passengers",
          title: "Passengers",
          value: 4,
          validation: false,
        },
        {
          id: "luggages",
          title: "Luggages",
          value: 2,
          validation: false,
        },
      ],
      babySeats: 0,
      countryCode: ""
    };
  },

  watch: {
    dateTmp(val) {
      this.date = this.formatDate(this.dateTmp);
    },
  },

  methods: {

    onSelect({ name, iso2, dialCode }) {
      this.countryCode = `+${dialCode}`;
      console.log(name, iso2, dialCode);
    },

    testRules() {
      let checkValidation = true;

      this.formText.forEach((item) => {
        if (!item.value) checkValidation = false;

        if (item.id == "customerEmail") {
          if (!this.emailPattern.test(item.value)) checkValidation = false;
        }
      });

      // this.formText.forEach( item => {
      //   if(item.validation == false) return this.errorMsg = 'Please enter all the required fields with correct data!'
      //   else return this.errorMsg = ''
      // })

      if (checkValidation) {
        this.errorMsg = "";
        this.createNewOrder();
      } else {
        this.errorMsg =
          "Please enter all the required fields with correct data!";
      }
    },

    changeAddress() {
      this.shouldReRender = true;

      this.showConfirmTrip = false;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    fetchVan(van, driver) {
      this.selectedVan = van;
      this.selectedDriver = driver;
      console.log(this.selectedVan);
      console.log(this.selectedDriver);
      this.gotVan = true;
    },

    checkVans() {
      this.showVan = !this.showVan;
      this.dateTime = `${this.formatDate(this.date)} ${this.time}`;

      let timeTaken = "3 heures 5 minutes";
      let pickUpDateTime = 1652932800;

      let simpleDate = dayjs.unix(pickUpDateTime).format("L LT");

      let addHours = dayjs(simpleDate).add(
        Number(timeTaken.split(" ")[0]),
        "hour"
      );
      let addMinutes = dayjs(addHours).add(
        Number(timeTaken.split(" ")[2]),
        "minute"
      );

      // console.log(dayjs(addMinutes).format('L LT'))

      let orderStart = pickUpDateTime;
      let orderEnd = dayjs(addMinutes).format("X");

      let dateTime = this.formatDate(this.date) + " " + this.time;
      let unixDateTime = Number(dayjs(dateTime).format("X"));

      // console.log(unixDateTime, orderStart)

      // console.log(unixDateTime, dayjs.unix(unixDateTime).format('L LT'))
      // console.log(orderStart, dayjs.unix(orderStart).format('L LT'))

      if (unixDateTime > orderStart && unixDateTime < orderEnd) {
        console.log("Yes it does");
      }

      // console.log(dayjs.unix(unixDateTime))
    },

    getMapData(payload) {
      this.tripTime = payload.tripTime;
      this.tripDistance = payload.tripDistance;
      this.pickUp = payload.pickUp;
      this.dropOff = payload.dropOff;

      this.showConfirmTrip = true;
    },

    async testDistanceAndStuff() {
      // Caculating distance from garage to source and getting the rest time

      const garageToSourceDT = await getDistanceAndTime(
        {
          lat: this.selectedVan.garageAddress.lat,
          lng: this.selectedVan.garageAddress.lng,
        },
        { lat: this.pickUp.lat, lng: this.pickUp.lng }
      );

      let restTimeAfterReachingSource = 0;

      driverRest.forEach((val) => {
        if (
          garageToSourceDT.distance.split(" ")[0] >= val.start &&
          garageToSourceDT.distance.split(" ")[0] <= val.end
        ) {
          restTimeAfterReachingSource = val.minutes;
        }
      });

      const garageToSource = {
        distance: garageToSourceDT.distance,
        time: Number(garageToSourceDT.time.split(" ")[0]),
        rest: Number(restTimeAfterReachingSource),
      };

      // Caculating distance from destination to garage and getting the rest time

      const destinationToGarageDT = await getDistanceAndTime(
        { lat: this.dropOff.lat, lng: this.dropOff.lng },
        {
          lat: this.selectedVan.garageAddress.lat,
          lng: this.selectedVan.garageAddress.lng,
        }
      );

      let restTimeAfterReachingDestination = 0;

      driverRest.forEach((val) => {
        if (
          destinationToGarageDT.distance.split(" ")[0] >= val.start &&
          destinationToGarageDT.distance.split(" ")[0] <= val.end
        ) {
          restTimeAfterReachingDestination = val.minutes;
        }
      });

      const destinationToGarage = {
        distance: destinationToGarageDT.distance,
        time: Number(destinationToGarageDT.time.split(" ")[0]),
        rest: Number(restTimeAfterReachingDestination),
      };

      console.log(garageToSource, destinationToGarage);
    },

    async createNewOrder() {
      // Caculating distance from garage to source and getting the rest time

      const garageToSourceDT = await getDistanceAndTime(
        {
          lat: this.selectedVan.garageAddress.lat,
          lng: this.selectedVan.garageAddress.lng,
        },
        { lat: this.pickUp.lat, lng: this.pickUp.lng }
      );

      let restTimeAfterReachingSource = 0;

      driverRest.forEach((val) => {
        if (
          garageToSourceDT.distance.split(" ")[0] >= val.start &&
          garageToSourceDT.distance.split(" ")[0] <= val.end
        ) {
          restTimeAfterReachingSource = val.minutes;
        }
      });

      const garageToSource = {
        distance: garageToSourceDT.distance,
        time: Number(garageToSourceDT.time.split(" ")[0]),
        rest: Number(restTimeAfterReachingSource),
      };

      // Caculating distance from destination to garage and getting the rest time

      const destinationToGarageDT = await getDistanceAndTime(
        { lat: this.dropOff.lat, lng: this.dropOff.lng },
        {
          lat: this.selectedVan.garageAddress.lat,
          lng: this.selectedVan.garageAddress.lng,
        }
      );

      let restTimeAfterReachingDestination = 0;

      driverRest.forEach((val) => {
        if (
          destinationToGarageDT.distance.split(" ")[0] >= val.start &&
          destinationToGarageDT.distance.split(" ")[0] <= val.end
        ) {
          restTimeAfterReachingDestination = val.minutes;
        }
      });

      const destinationToGarage = {
        distance: destinationToGarageDT.distance,
        time: Number(destinationToGarageDT.time.split(" ")[0]),
        rest: Number(restTimeAfterReachingDestination),
      };

      // Calculating Fare

      const parameters = await this.$store.dispatch("getParameters");
      let seat = `0.${parameters.seatPercent}`;
      let luggage = `0.${parameters.luggagePercent}`;
      if (Number(Number(this.formText[3].value)) < 5) {
        seat = "0";
      }

      if (Number(Number(this.formText[4].value)) < 7) {
        luggage = "0";
      }

      let calculateDistance =
        Number(this.tripDistance.split(" ")[0]) * Number(parameters.pricePerKm);

      console.log(calculateDistance);
      let travelPrice = Number(parameters.basicFlatRate) + calculateDistance;

      console.log(travelPrice);
      let totalPrice = travelPrice * (1 + Number(seat) + Number(luggage));

      console.log(totalPrice);

      const finalFare = Math.round((totalPrice + Number.EPSILON) * 100) / 100;

      console.log(finalFare);

      // Preparing object for firebase

      const payload = {
        customId: `SN-CMP-${Math.floor(Math.random() * 1000000)}`, // SN-CMP-01
        vanRef: this.selectedVan.id,
        driverRef: this.selectedDriver.id,
        customerRef: "",
        vanCustomId: this.selectedVan.customId, // SN-VC-01
        vanBrandModel: `${this.selectedVan.brand}, ${this.selectedVan.model}`, // brand + model in a string
        driverCustomId: this.selectedDriver.customId, // SN-CH-01
        driverFullName: `${this.selectedDriver.firstName} ${this.selectedDriver.lastName}`,
        customerCustomId: `SN-CP-${Math.floor(Math.random() * 1000000)}`, // CP-01
        customerFullName: this.formText[0].value,
        customerPhoneNumber: `${this.countryCode} ${this.formText[1].value}`,
        customerEmail: this.formText[2].value,
        orderDateTime: Math.round(new Date().getTime() / 1000),
        pickUpDateTime: Math.round(
          new Date(`${this.formatDate(this.date)} ${this.time}`).getTime() /
            1000
        ),
        garageToSource: garageToSource,
        destinationToGarage: destinationToGarage,
        dropOffDateTime: 0,
        pickUpLocation: this.pickUp, // Google maps coordinates
        dropOffLocation: this.dropOff, // Google maps coordinates
        estimatedTime: this.tripTime, // estimated from google maps
        estimatedDistance: this.tripDistance, // estimated from google maps
        estimatedFare: finalFare,
        passengers: Number(this.formText[3].value),
        luggages: Number(this.formText[4].value),
        babySeats: Number(this.babySeats), // 2 max
        payment: {
          date: this.formatDate(this.date),
          status: "Pending",
          estimatedFare: finalFare,
          actualFare: 0,
        },
        parameters: {
          basicFlatRate: Number(parameters.basicFlatRate),
          pricePerKm: Number(parameters.pricePerKm),
          seatPercent: Number(parameters.seatPercent),
          luggagePercent: Number(parameters.luggagePercent),
        },
        orderStatus: "Pending",
        preTripReport: {},
        postTripReport: {},
        postTrip: {},
        tripStatus: "",
      };

      if(!this.formText[0].value || !this.formText[1].value || !this.formText[2].value || !this.emailPattern.test(this.formText[2].value)) return false

      console.log(payload);

      const newOrderResponse = await this.$store.dispatch(
        "order/createOrder",
        payload
      );

      if (newOrderResponse.code == 1) {
        this.$router.push("/manager/orders");
      } else {
        console.log("Failed to create order");
      }
    },
  },
};
</script>

<style></style>
